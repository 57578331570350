html,
body,
#root {
    background-color: rgb(34, 39, 48);
    margin: 0;
    padding: 0;
    height: 100vh;
}

*,
:after,
:before {
    border: 0 solid #e5e7eb;
    box-sizing: border-box;
}

#root {
    display: flex;
    flex-direction: column;
}

.viewer-root {
    overflow: hidden;
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    // height: 100vh;
    max-height: 100vh;
    position: relative;
    background-color: #222730;
}

.footer-note {
    flex: 0;
    margin: 12px 0;
    align-self: center;
    color: white;
    font-size: 12px;
    font-family: monospace;
}

.view-wrapper {
    display: flex;
    flex: 0;
    flex-direction: row-reverse;
    justify-content: center;
    max-height: 90vh;

    &.has-file {
        height: 90vh;
        min-height: 90vh;
    }
}

.text-thin {
    color: rgb(51 65 85);
    font-weight: 300;
    font-size: .75rem;
    line-height: 1rem;
    margin-left: 8px;
}

.text-thin-mono {
    font-family: monospace;
    font-weight: 100;
}

.text-mono {
    font-family: monospace;
    font-weight: 400;
}

.tree-label-wrapper {
    align-items: center;
    display: flex;
    margin-left: 1rem;
    padding: 0 6px;
    border-radius: 4px;
    cursor: pointer;
    border: 1px solid transparent;

    &:hover {
        background-color: #7f96db20;
    }

    &.selected {
        background-color: #7f96db42;
        border: 1px dashed #3e48da;
    }
}

.download-label {
    text-decoration-line: underline;
    color: rgb(3 105 161);
    font-weight: 300;
    font-size: .75rem;
    line-height: 1rem;
    cursor: pointer;
    margin-left: 8px;
    padding: 2px 4px;


    &:hover {
        background-color: #7f96db40;
        color: blue;
    }
}

.btn-folder-expand {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.btn-folder-expand-text {
    color: rgb(75 85 99);
    font-weight: 300;
    background-color: rgb(229 231 235);
    display: flex;
    justify-content: center;
    align-items: center;
    flex: auto;
    flex-direction: column;
    width: .75rem;
    height: .75rem;
}

.btn-folder-expand-text>span {
    /* margin: auto; */
    width: 100%;
    height: 100%;
    line-height: 10px;
}

.dropzone {
    position: relative;
    padding: 16px 24px;
    background-color: white;
    border-radius: .5rem;
    overflow: auto;
    flex: auto;
    /* width: 100%; */

    max-width: 42rem;
    /* margin-left: auto; */
    /* margin-right: auto; */
}

.drag-callout-wrapper {
    position: absolute;
    pointer-events: none;
    user-select: none;
    inset: 0;
    background-color: #222730c4;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    font-weight: 300;
    padding: 24px;
}

.value-viewer {
    background-color: #dcdcdc;
    transform: translateX(-20px);
    display: flex;
    align-items: stretch;
    padding-left: 42px;
    /* z-index: -2; */

    &::after {
        content: ' ';
        display: block;
        position: absolute;
        inset: 0;
        box-shadow: #0000004a 6px 0 8px 5px;
        width: 10px;
        height: 100%;
    }

    span.float-placeholder {
        color: #222730c4;
        font-size: 14px;
        font-weight: 200;
        text-align: center;
        flex: 1;
    }

    .float-data-wrapper {
        border: 1px solid #c2c2c2;
        flex: 1;
        border-radius: 8px;
        padding: 8px;
        background-color: #f0f0f0;
        font-size: 12px;
        color: black;
        font-weight: 500;
        font-family: monospace;
        display: flex;
        flex-direction: column;

        .float-nav {
            padding: 8px;


            .float-nav-actions {
                float: right;
            }
        }

        .float-grid {
            display: flex;
            flex-wrap: wrap;
            flex: 1;
            align-content: flex-start;
            justify-content: flex-start;
            /* gap: 2px; */

            .float-value {
                flex: 0 0 20%;
                border-radius: 4px;
                padding: 1px;
                /* max-width: 20%; */

                &>* {
                    background-color: #e6e6e7;
                    padding: 3px 2px;
                    text-align: center;
                }
            }
        }
    }
}

.dropzone.has-file {
    min-height: 80vh;
}

.parsing-error {
    color: rgb(185 28 28);
    line-height: 1.75rem;
    font-size: 1rem;
}

.dropzone-info {
    color: rgb(75 85 99);
    line-height: 1.75rem;
    font-size: 1rem;
    text-align: center;
}

.footnote-quiet {
    color: rgb(156 163 175);
    font-size: .875rem;
    line-height: 1.25rem;
    margin-top: 24px;
}

.main-text-container {
    font-weight: 600;
    font-size: 1rem;
    padding-top: .5rem;
}

.data-explorer-node {
    border-color: rgb(229 231 235);
    border-left-width: 1px;
    margin-left: 1rem;
}

.data-explorer-folder {
    align-items: center;
    display: flex;
    margin-left: .75rem;
}

.data-explorer-filename {
    font-family: monospace;
    margin-left: 8px;
}

.data-explorer-wrapper {
    font-size: .875rem;
    line-height: 1.25rem;
    margin-top: .25rem;
}

.file-title {
    margin-left: .5rem;
    color: #374151;
}